<template>

</template>

<script>
export default {
    name: "test3",
    mounted() {
        let that = this
        // 检查打卡成功
        let u = navigator.userAgent;
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isiOS){
            window.onpageshow = (event) => {
                that.checkRead()
            };
        } else {
            that.checkRead()
        }
    },
    activated () {
        this.checkRead()
    },
    methods:{
        checkRead(){
            // document.location.replace(localStorage.getItem('test_article_url'))
            let test_article_url = localStorage.getItem('test_article_url')
            localStorage.removeItem('test_article_url')
            if(test_article_url){
                document.location.replace(test_article_url)
            } else {
                this.$router.back()
            }
        }
    }
}
</script>

<style scoped>

</style>
